<template>
    <div class="container">
        <div class="card card-container">
            <!-- <img class="profile-img-card" src="//lh3.googleusercontent.com/-6V8xOA6M7BA/AAAAAAAAAAI/AAAAAAAAAAA/rzlHcD0KYwo/photo.jpg?sz=120" alt="" /> -->
            <img id="profile-img" class="profile-img-card" src="//ssl.gstatic.com/accounts/ui/avatar_2x.png" />
            <p id="profile-name" class="profile-name-card"></p>
            <form class="form-signin" @submit.prevent="submitForm">
                <span id="reauth-email" class="reauth-email"></span>
                <div class="row" v-if="has_error">
                    <div class="col">
                        <div class="alert alert-danger">
                            {{error_msg}}
                        </div>
                    </div>
                </div>
                <div class="row" v-if="check_user">
                    <div class="col form-group">
                        <input type="text" id="inputEmail" class="form-control" placeholder="Username / Code de badge" v-model="username" required autofocus>
                    </div>
                </div>
                <div class="row" v-if="validate_code">
                    <div class="col form-group">
                        <input type="number" class="form-control" id="code_validation" name="code_validation" placeholder="Code Activation" required autofocus v-model="code">
                    </div>
                </div>
                <div class="row" v-if="set_password">
                    <div class="form-group">
                        <input type="password" id="password" class="form-control" placeholder="Mot de passe" required autofocus v-model="password">
                        <input type="password" id="confirm_password" class="form-control" placeholder="Confirmation Mot de passe" required v-model="confirm_password"> 
                    </div>
                </div>
                <div class="row" v-if="login">
                    <div class="col-12">
                        <div class="form-group">
                            <label>{{username}}</label>
                            <input type="password" id="password" class="form-control" placeholder="Mot de passe" required autofocus v-model="password">
                        </div>
                    </div>
                    <div id="remember" class="checkbox">
                        <label>
                            <input type="checkbox" value="remember-me"> Rester connecté
                        </label>
                    </div>
                </div>
                <button class="btn btn-lg btn-primary btn-block btn-signin" type="submit">{{ loginText }}</button>
            </form><!-- /form -->
            <a href="#" class="forgot-password">
                Mot de passe oublié?
            </a>
        </div><!-- /card-container -->
    </div><!-- /container -->
</template>
<script>
import {LOGIN, CHECK_USERNAME, VALIDATE_ACTIVATION_CODE, SET_PASSWORD} from '../graphql/user'
import {mapMutations} from 'vuex'
export default {
    name: 'Login',
    data(){
        return {
            username: null,
            password: null,
            confirm_password: null,
            code: null,
            uid: null,
            check_user: true,
            validate_code: false,
            set_password: false,
            login: false,
            has_error: false,
            error_msg: null
        }
    },
    methods: {
        ...mapMutations({
            loggedUser: 'auth/LOGIN_USER'
        }),
        submitForm(){
            this.has_error = false
            if(this.check_user){
                this.$apollo.mutate({
                    mutation: CHECK_USERNAME,
                    variables: {login: this.username},
                    update: (store, {data: {checkUser:user}}) => {
                        console.log(user)
                        if(user.uid !== null) this.check_user = false
                        if(!user.enable) {
                            this.set_password = false
                            this.login = false
                            this.validate_code = true
                        }
                        else{
                            this.set_password = false
                            this.login = true
                            this.validate_code = false
                        }
                    }
                }).then((data) => {
                    // Result
                    console.log(data)
                }).catch((error) => {
                    console.error(error)
                    this.has_error = true
                    this.error_msg = error
                })
            }
            if(this.validate_code){
                 this.$apollo.mutate({
                    mutation: VALIDATE_ACTIVATION_CODE,
                    variables: {login: this.username, codeActivation: parseInt(this.code)},
                    update: (store, {data: {validateCode }}) => {
                        console.log(validateCode)
                        if(validateCode !== null) {
                            this.validate_code = false
                            this.set_password = true
                            this.login = false
                            this.uid = validateCode
                        }
                    }
                }).then((data) => {
                    // Result
                    console.log(data)
                }).catch((error) => {
                    console.error(error)
                    this.has_error = true
                    this.error_msg = error
                })
            }
            if(this.set_password){
                if(this.confirm_password !== this.password) {
                    console.error("Password not match")
                    return
                }
                this.$apollo.mutate({
                    mutation: SET_PASSWORD,
                    variables: {"password": this.password, "uid": this.uid},
                    update: (store, {data: {setPassword}}) => {
                        console.log(setPassword)
                        this.loggedUser(setPassword)
                        location.reload();
                        
                    }
                }).then((data) => {
                    // Result
                    console.log(data)
                }).catch((error) => {
                    console.error(error)
                    this.has_error = true
                    this.error_msg = error
                })
            }
            if(this.login){
                this.$apollo.mutate({
                mutation: LOGIN,
                variables: {
					"login": this.username,
                    "password": this.password
				},
                update: (store, { data: token }) => {
                    console.log(token)	
                    this.loggedUser(token.login)
                    location.reload();
				},
            }).then((data) => {
                    // Result
                    console.log(data)
                }).catch((error) => {
                    console.error(error)
                    this.has_error = true
                    this.error_msg = error
                })
            }
        }
    },
    computed: {
        loginText () {
            if(this.check_user) return 'Identification'
            if(this.validate_code) return 'Validation'
            if(this.set_password) return 'Enregistrer'
            else return 'Connexion'
        }
    }
}
</script>
<style  scoped>
/*
 * Specific styles of signin component
 */
/*
 * General styles
 */
body, html {
    height: 100%;
    background-repeat: no-repeat;
    background-image: linear-gradient(rgb(33, 137, 182), rgb(15, 34, 119));
}

.card-container.card {
    max-width: 350px;
    padding: 40px 40px;
}

.btn {
    font-weight: 700;
    height: 36px;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    cursor: default;
}

/*
 * Card component
 */
.card {
    background-color: #F7F7F7;
    /* just in case there no content*/
    padding: 20px 25px 30px;
    margin: 0 auto 25px;
    margin-top: 50px;
    /* shadows and rounded borders */
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
    width: 96px;
    height: 96px;
    margin: 0 auto 10px;
    display: block;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}

/*
 * Form styles
 */
.profile-name-card {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin: 10px 0 0;
    min-height: 1em;
}

.reauth-email {
    display: block;
    color: #404040;
    line-height: 2;
    margin-bottom: 10px;
    font-size: 14px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.form-signin #inputEmail,
.form-signin #inputPassword {
    direction: ltr;
    height: 44px;
    font-size: 16px;
}

.form-signin input[type=email],
.form-signin input[type=password],
.form-signin input[type=text],
.form-signin button {
    width: 100%;
    display: block;
    margin-bottom: 10px;
    z-index: 1;
    position: relative;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.form-signin .form-control:focus {
    border-color: rgb(104, 145, 162);
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgb(104, 145, 162);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgb(104, 145, 162);
}

.btn.btn-signin {
    /*background-color: #4d90fe; */
    background-color: rgb(104, 145, 162);
    /* background-color: linear-gradient(rgb(104, 145, 162), rgb(12, 97, 33));*/
    padding: 0px;
    font-weight: 700;
    font-size: 14px;
    height: 36px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    border: none;
    -o-transition: all 0.218s;
    -moz-transition: all 0.218s;
    -webkit-transition: all 0.218s;
    transition: all 0.218s;
}

.btn.btn-signin:hover,
.btn.btn-signin:active,
.btn.btn-signin:focus {
    background-color: rgb(12, 97, 33);
}

.forgot-password {
    color: rgb(104, 145, 162);
}

.forgot-password:hover,
.forgot-password:active,
.forgot-password:focus{
    color: rgb(12, 97, 33);
}
</style>