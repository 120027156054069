import gql from 'graphql-tag'

export const LOGIN = gql`
  mutation login($login: String!, $password: String!) {
  login(login: $login, password: $password)
}
`

export const CHECK_USERNAME = gql`
mutation Mutation($login: String!) {
  checkUser(login: $login) {
    uid
    firstConnexion
    enable
  }
}
`

export const SET_PASSWORD = gql`
mutation Mutation($password: String!, $uid: String!) {
  setPassword(password: $password, uid: $uid)
}
`

export const VALIDATE_ACTIVATION_CODE = gql`
mutation Mutation($login: String!, $codeActivation: Int!) {
  validateCode(login: $login, codeActivation: $codeActivation)
}
`



export const REGISTRATION = gql`
mutation Mutation($fullname: String!, $telephone: String!, $typeCompte: String!, $pays: String!, $login: String!) {
  register(fullname: $fullname, telephone: $telephone, typeCompte: $typeCompte, pays: $pays, login: $login)
}
`